<template>
  <div class="bg-slate-50">
    
    <div class="grid grid-cols-6">
      <div class="col-span-1 menu-dark ">
        <LeftSidebar  v-if="!showHomeComponents" />
        <LeftSidebarHome  v-if="showHomeComponents" />
      </div>
      <div class="col-span-4">
        <div class="">
          <AlertMessage :msg="msg" :msgType="msgType" />
        </div>
        <div class="">
          <NavbarPublic v-if="!showHomeComponents" />
          <NavbarHome   v-if="showHomeComponents" />
        </div>
        <router-view></router-view>
      </div>
      
      <div class="col-span-1">
        <RightSidebarPublic  v-if="!showHomeComponents" />
        <RightSidebarHome  v-if="showHomeComponents" />
      </div>
    </div>
    <div class="">
      <FooterPublic  v-if="!showHomeComponents" />
      <FooterHome  v-if="showHomeComponents" />
    </div>  
    
    
  </div>
     
</template>

<script>
// import Login from './components/auth/Login.vue'
// import FooterPublic from './components/parts/FooterPublic.vue'
import NavbarPublic from './components/parts/NavbarPublic.vue'
import NavbarHome from './components/parts/NavbarHome.vue'
import LeftSidebar from './components/parts/LeftSidebar.vue'
import LeftSidebarHome from './components/parts/LeftSidebar.vue'
import RightSidebarPublic from './components/parts/RightSidebarPublic.vue'
import RightSidebarHome from './components/parts/RightSidebar.vue'
import FooterPublic from './components/parts/FooterPublic.vue'
import FooterHome from './components/parts/FooterHome.vue'
import AlertMessage from './components/parts/AlertMessage.vue'
import Auth from '@/auth/Auth'
export default {
  data(){
    return {
      showHomeComponents: false,
      msg: '',
      msgType: '',
    }
  },
  name: 'App',
  components: {
    RightSidebarPublic,
    RightSidebarHome,
    NavbarPublic,
    NavbarHome,
    LeftSidebar,
    LeftSidebarHome,
    FooterPublic,
    FooterHome,
    AlertMessage,
  },
  methods:{
    
  },
  mounted(){

    if(Auth.check())
    {
        this.showHomeComponents = true;
    }
  }
}
</script>

<style>

</style>
