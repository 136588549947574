<template>
    <div class="p-10">
        <div class="grid grid-cols-3 gap-5">
            <!-- Statistics cards -- Total Messsages-->
            <div class="w-full h-40 bg-white rounded-sm shadow-md">
              <div class="flex justify-between mx-10 items-center mt-3 h-1/3">
                <span class="">
                  <img class="w-10 h-10" src="@/assets/icons/total-messages.svg" alt="" srcset="">
                </span>
                <span class="font-semibold">
                  Total Messages
                </span>
                
              </div>
              <div class="mt-3  h-1/3 text-center">
                {{ totalMessages }}
              </div>
              <div class="bg-blue-500 h-auto">
                &nbsp;
              </div>
            </div>
            <!-- Statistics cards -- Total Read Messsages-->
            <div class="w-full h-40 bg-white rounded-sm shadow-md">
              <div class="flex justify-between mx-10 items-center mt-3 h-1/3">
                <span class="">
                  <img class="w-10 h-10" src="@/assets/icons/total-messages.svg" alt="" srcset="">
                </span>
                <span class="font-semibold">
                  Read Messages
                </span>
                
              </div>
              <div class="mt-3  h-1/3 text-center">
                {{ readMessages }}
              </div>
              <div class="bg-blue-500 h-auto">
                &nbsp;
              </div>
            </div>
            <!-- Statistics cards -- Total UnRead Messsages-->
            <div class="w-full h-40 bg-white rounded-sm shadow-md">
              <div class="flex justify-between mx-10 items-center mt-3 h-1/3">
                <span class="">
                  <img class="w-10 h-10" src="@/assets/icons/total-messages.svg" alt="" srcset="">
                </span>
                <span class="font-semibold">
                  Unread Messages
                </span>
                
              </div>
              <div class="mt-3  h-1/3 text-center">
                {{ unreadMessages }}
              </div>
              <div class="bg-blue-500 h-auto">
                &nbsp;
              </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
  data(){
    return {
      totalMessages: 0,
      readMessages: 0,
      unreadMessages: 0
    }
  },
  name: 'HelloWorld',
  props: {
    msg: String
  },
  methods:{
    
  },
  mounted(){
    
    axios.get(this.server+'/api/v1/msg/stats')
      .then((response) =>{
        console.log(response)
        if(response.data.resCode == 200){
        //   Auth.login(response.data.token, response.data.user)
            this.totalMessages = response.data.data.totalMessages;
            this.unreadMessages = response.data.data.unreadMessages;
            this.readMessages = response.data.data.readMessages;
        }
      })
      .catch((error) => console.log(error))
  }
}
</script>