import { createApp } from 'vue'
import router from './routes/Router.js'
import App from './App.vue'
import './index.css'

let app = createApp(App)
app.mixin({
    data: function() {
      return {
        get server() {
          return "https://gateway.shiproro.app";//Production
          // return "https://buybauxite.com";//Stagging
        }
      }
    }
  })
app.use(router).mount('#app')
