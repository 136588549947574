<template>
  <div class="">
    <div class="w-64 h-52 m-auto">
        <div class="flex justify-center">
            <img src="./../../assets/logo.png" alt="" class="w-20 h-20">
        </div>
        <!-- Error/success messages / alerts -->
        <div class="">
            
        </div>
        <!-- username -->
        
        <div class="flex  space-x-3 mt-3">
            <div class="">
                <label for="">Username</label>
            </div>
            <div class="">
                <input v-model="email" type="text" class="py-1.5 border rounded-md px-2" name="email" placeholder="Username/Email">
            </div>
        </div>
        <!-- Password -->
        <div class="flex space-x-3 mt-3">
            <div class="">
                <label for="">Password</label>
            </div>
            <div class="">
                <input  v-model="password" type="password" class="py-1.5 border rounded-md  px-2" name="password" placeholder="Password">
            </div>
        </div>
        <div class="flex justify-center mt-3">
            <button @click="login" class="py-1.5 px-3 bg-blue-600 text-white rounded-md">Login</button>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Auth from '@/auth/Auth'
export default {
  data(){
    return {
      email: '',
      password:''
    }
  },
  name: 'HelloWorld',
  props: {
    msg: String
  },
  methods:{
    login(){
      let app = this;
      axios.post(this.server+'/api/login', {
        email: this.email,
        password: this.password
      })
      .then((response) =>{
        // console.log(response)
        if(response.data.resCode == 200){
          
          Auth.login(response.data.data.access_token, response.data.data.user)
          this.$router.push('/')
        }
      })
      .catch(function(error){
        if(error.response)
        app.$parent.msg = error.response.data;
        app.$parent.msgType = "error";
      });
    }
  } 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
