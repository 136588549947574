<template>
    <div class="w-full mx-16 bg-white mt-5">
        <!-- Search -->
        <div class="">
            <div class="">
                <input v-model="searchTerm" placeholder="Filter messages" type="text" class="w-full border py-1.5 px-3">
            </div>
        </div>
        <!-- <div class="">
            <div class="flex space-x-1  py-1">
                <div class=" py-0.5">
                    <input type="checkbox" class="h-4 w-4 border" id="search">
                </div>
                <div class="">
                    <label for="search">
                        Select all
                    </label>
                </div>
                
            </div>
        </div> -->
        <div class="bg-white">
            <!-- Message threads -->
            <div @click="open(message.id)" class="p-5 border-b  cursor-pointer"  
            :class="{ 'bg-gray-50': !message.status}" 
            v-for=" message in filterMessages"  :key="message.id">
                <!-- Upper section -->
                <div class="flex">
                    <div class="w-12">
                        <img v-if="message.user && message.user.pic" class="w-12 h-12 rounded-full" :src=" message.user.pic " alt="" srcset="">
                        <img v-else class="w-12 h-12 rounded-full" src="@/assets/icons/profile.svg" alt="" srcset="">

                    </div>
                    <!-- Name section -->
                    <div class="  ml-3 w-full">
                        <div class="flex justify-between">
                                <!-- Name -->
                            <div class="">
                                <h3 class="font-bold text-xl">{{ message.user ? message.user.name : ""  }}</h3>
                            </div>
                            <!-- Attachments -->
                            <div class="flex justify-end space-x-1">
                                <!-- Attachment icon -->
                                <div class=" pt-1">
                                    <img v-if="hasAttachment(message)" class="w-4 h-4 " src="@/assets/icons/attachment.svg" alt="" srcset="">
                                </div>
                                <div class="">
                                    <p class="text-gray-400">{{ message.humanReadableDate }}</p>
                                </div>
                            </div>
                        </div>
                        <!-- Message section -->
                        <div class="">
                            <p class="text-gray-400">
                                {{  message.subject }}
                            </p>
                        </div>
                    </div>
                    
                </div>
                <!-- Lower body section -->
                <div class="  ml-3 ">
                    <div class="flex">
                        <!-- Checkbox -->
                        <div class="w-12">
                            <!-- <input type="checkbox" class="h-4 w-4 border" id="search"> -->
                        </div>
                        <!-- Body excerpt -->
                        <div class="">
                            <p class="text-gray-300">
                                {{ message.body.substring(0,200)+" ..." }}
                            </p>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
        
    </div>
</template>
<script>

import axios from 'axios'

export default {
  data(){
    return {
        messages: [],
        users: [],
        searchTerm:'',
    }
  },
  methods:{
    
        open(msgId){
            this.$router.push('/inbox/'+msgId);
        },
        
        hasAttachment(message){
            
            if(message.attachments && message.attachments.length > 0)
                return true;
            if(message.replies && message.replies.length > 0)
            {
                for(let i = 0; i < message.replies.length; i++)
                {
                    if(message.replies[i].attachments && message.replies[i].attachments.length > 0)
                        return true;
                }
            }
            return false;
        },
        updateMessages()
        {
            axios.get(this.server+'/api/v1/msg/messages',{
                params: {
                    support: true
                }})
            .then((response) =>{
                // console.log(response)
                if(response.data.resCode == 200){
                    if(this.messages.length < response.data.data.messages.length)
                    {
                        this.messages = response.data.data.messages; 
                        
                        let permission =  Notification.requestPermission();
                        if(permission == "granted")
                        {
                            const newMessageNotification = new Notification('You have a new message.');
                            setTimeout(() => newMessageNotification.close(), 10000);
                        }
                        
                    }
                }
            })
            .catch((error) => console.log(error));
        }
  },
  computed: {
    filterMessages() {
        return this.messages.filter( 
                msg => msg.subject.toLowerCase().match(this.searchTerm.toLowerCase()) 
                || msg.user.name.toLowerCase().match(this.searchTerm.toLowerCase()) 
                || msg.body.toLowerCase().match(this.searchTerm.toLowerCase()) 
            );
        }

    },
  mounted(){
    
    axios.get(this.server+'/api/v1/msg/messages',{
        params: {
            support: true
        }})
      .then((response) =>{
        // console.log(response)
        if(response.data.resCode == 200){
            
            this.messages = response.data.data.messages;
            
        }
      })
      .catch((error) => console.log(error));

      setInterval(this.updateMessages, 1*60*1000);
  },
}
</script>