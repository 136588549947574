
// import VueRouter from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/components/auth/Login.vue'
import Error404 from '@/components/errors/ErrorNotFound.vue'
import Auth from '@/auth/Auth';
import DashboardPage from '@/components/pages/DashboardPage.vue'
import InboxPage from '@/components/pages/InboxPage.vue'
import InboxThread from '@/components/pages/InboxThread.vue'
// Routes
const routes = [
  {
    path: '/error-404',
    name: 'error-not-found',
    component: Error404,
    
  },
  {
    path: '/login',
    name: 'auth-login',
    component: Login ,
    
  },
  {
    path: '/dashboard',
    component: DashboardPage,
    name: "dashboard-page",
    meta: {
        requiresAuth: true
    }
  },
  {
    path: '/inbox/:id',
    component: InboxPage,
    name: "inbox-page",
    meta: {
        requiresAuth: true
    }
  },
  {
    path: '/',
    component: DashboardPage,
    name: "home-page",
    meta: {
        requiresAuth: true
    }
  },
  {
    path: '/threads',
    component: InboxThread,
    name: "thread-page",
    meta: {
        requiresAuth: true
    }
  }
];
const router = createRouter({
  history: createWebHistory(),
  routes
  
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) ) {
      if (Auth.check()) {
          next();
          return;
      } else {
          router.push('/login');
      }
  } else {
      next();
  }
});

export default router
