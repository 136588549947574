<template>
    <div class="flex justify-between theme-orange-bg mx-16 mt-5 py-3 rounded-md px-10 w-full">
        <div class="flex justify-start">

        </div>
        <div class="flex justify-end">
            <div class="">
                <button @click="logout" class="px-2 py-1.5 bg-blue-600 text-white rounded-md"> Logout</button>
            </div>
        </div>
    </div>
</template>
<script>
import Auth from '@/auth/Auth'
import axios from 'axios'
   export default {
    methods:{
        logout() {
        axios.get(this.server + '/api/logout')
        .then(({data}) => {
            console.log(data);
            Auth.logout(); //reset local storage
            this.$router.push('/login');
        })
        .catch((error) => {
            console.log(error);
        });
        }
    }
   }
</script>