<template>
    <div class="h-screen">
        <div class="flex justify-center mt-5">
            <img src="@/assets/logo.png" alt="" class="w-24 h-24">
        </div>
        <div class="mt-5">
            <ul class="">
                <li class="px-5">
                    <router-link to="/">
                        <div :class="{ active: active == 'home' }" class="flex space-x-5  cursor-pointer  pl-3">
                            <span class="pt-1">
                                <img src="@/assets/logo.png" alt="" class="w-6 h-6">
                            </span>
                            <span class="">
                                
                                    <i class=""></i>
                                    <span class="text-xl">Home</span>
                                
                            </span>
                        </div>
                    </router-link>
                    
                </li>
                <li class="px-5 mt-3">
                    <router-link to="/threads">
                        <div  :class="{ active: active == 'inbox' }" class="flex space-x-5 cursor-pointer  pl-3">
                            <span class="pt-1">
                                <img src="@/assets/logo.png" alt="" class="w-6 h-6">
                            </span>
                            <span class="">
                                <i class=""></i>
                                <span class="text-xl">Inbox</span>
                            </span>
                        </div>
                    </router-link>
                    
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import router from '@/routes/Router';
export default {
   
        computed: {
            active(){
                //console.log(router.currentRoute.value.name);
                if (router.currentRoute.value.name == "inbox-page") {
                    
                    return "inbox" ;
                }
                else if (router.currentRoute.value.name == "home-page"
                || router.currentRoute.value.name == "dashboard-page"
                ) {
                    return  "home" ;
                }
                return '';
            },
        }
    }
</script>