<template>
    <div class="mt-5 w-full  mx-16">
        <div class="">
            <!-- <div class="w-1/4 border-r h-128 overflow-y-scroll mx-5 bg-white">
                <div :class="{ 'bg-gray-200': message.id == openMessage.id}" class="py-2 border-b  px-5" v-for=" message in messages"  :key="message.id">
                    <div class="h-full w-full" >
                        <router-link :to="{ path: '/inbox/' + message.id }">
                            <span :class="{'font-bold': !message.status}">{{  message.subject }}</span>
                        </router-link>
                    </div>
                </div>
            </div> -->
            <div class="w-full   h-128 overflow-y-scroll " id="msg-box">
                <div class="bg-white px-5 rounded-lg shadow-lg  pb-5" v-if="openMessage.id">
                    <div class="border-b py-3">
                        <div class="">
                            <h3 class="text-left font-bold text-xl">{{ openMessage.subject }}</h3>
                        </div>
                        <div class="">
                            <!-- Profile -->
                            <div class="">
                                <div class="flex space-x-4 items-center">
                                    <div class="">
                                        <img v-if="user.pic" class="w-10 h-10 rounded-full" :src="user.pic" alt="" srcset="">
                                        <img v-else class="w-10 h-10 rounded-full" src="@/assets/icons/profile.svg" alt="" srcset="">

                                    </div>
                                    <div class="">
                                        <span class="font-semibold">{{  user.name }}</span>
                                        <span class="text-gray-600 ml-5">{{ openMessage.created_at }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="py-5 border-b">
                        <div class="">
                            <p class="text-left" v-html="openMessage.body"></p>
                        </div>
                        <div class=" py-2.5">
                            <div class="" v-if="openMessage.attachments">
                                Attachments
                                <hr class="w-1/2"/>
                            </div>
                            <div class="flex space-x-3">
                                <div class="pt-2" v-for="attachment in openMessage.attachments" :key="attachment.id">
                                    <a :href="attachment.file_path" target="_blank" class="flex space-x-3">
                                        <img src="@/assets/icons/attachment.svg" alt="" class="h-5 "> <span class="underline text-blue-400">{{ attachment.file_name }}</span>
                                    </a>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                    <div  v-for="reply in openMessage.replies.slice(1)" :key="reply.id">
                        <div class="w-2/3 rounded-lg shadow p-5 text-white mt-5" :class="{'bg-sky-300': reply.to != -1,'float-left': reply.to != -1, 'bg-gray-300': reply.to == -1, 'float-right': reply.to == -1}"   v-if=" !(reply.to == -1 && reply.to_deleted == 1) ">
                            <div class="flex justify-between">
                                <div>
                                    <p class="text-left text-lg"  v-html="linkify(reply.body)"></p>
                                </div>
                                <div class="pt-2 float-right" v-if="reply.to == -1">
                                    <img @click="deleteMsg(reply.id)" src="@/assets/icons/delete.svg" alt="" class="h-4 cursor-pointer " title="Delete this reply"> 
                                </div>
                            </div>
                            
                            <div class=" py-2.5"  v-if="reply.attachments">
                                <div class="">
                                    Attachments
                                    <hr class="w-1/2"/>
                                </div>
                                <div class="flex space-x-3">
                                    <div class="pt-2" v-for="attachment in reply.attachments" :key="attachment.id">
                                        <a :href="attachment.file_path" target="_blank" class="flex space-x-3">
                                            <img src="@/assets/icons/attachment.svg" alt="" class="h-5 "> <span class="underline text-blue-400">{{ attachment.file_name }}</span>
                                        </a>
                                    </div>
                                </div>
                                
                                
                            </div>
                        </div>
                        
                    </div>
                    <div class="pt-5 clear-both">
                        
                        <div class="">
                            <textarea v-model="message" name="" id="" class="w-full border p-3" rows="10" placeholder="Type message here .."></textarea>
                        </div>
                        <div class="flex">
                            
                             <input id="file" @change="handleFile" type="file" name="files[]"  class="px-2 py-1.5 " multiple>
                            
                        </div>
                        <div class="flex  justify-center">
                            <div class="">
                                <button @click="sendMessage" class="px-2 py-1.5 bg-blue-600 text-white rounded-md">Send</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import axios from 'axios'
export default {
  data(){
    return {
        message: '',
        openMessage:{},
        messages: [],
        attachments: [],
        user: {
            name: '',
            pic: ''
        },
    }
  },
  methods:{
    sendMessage(){
        
        var formData = new FormData();
        for(var i = 0; i < this.attachments.length; i++){
            formData.append('files[]', this.attachments[i]);
        }
        formData.append("subject", "Reply to: "+this.openMessage.subject);
        formData.append("body", this.message);
        formData.append("category", 1);
        formData.append("to", this.openMessage.from);
        formData.append("from", -1);
        formData.append("reply_to", this.openMessage.id);
        axios.post(this.server+'/api/v1/msg/store',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then(res=>{
            
            if(res.data.resCode == 200){
                this.openMessage.replies.push(res.data.data);
                this.message = ''
                this.attachments = []
                let file = document.getElementById('file');
                file.value = '';
            }
            
        })
    },
    markOpenMessageAsRead(){
        if(this.openMessage.id && this.openMessage.status == 0)
        {
            axios.patch(this.server+'/api/v1/msg/mark-read/'+this.openMessage.id,{
                user: -1
            }).then(res=>{
                if(res.data.resCode == 200){
                    this.openMessage.status = 1
                }
                // console.log(res);
            })

        }
        
        this.scrollToBottom();
    },
    scrollToBottom(){
        var objDiv = document.getElementById("msg-box");
        objDiv.scrollTop = objDiv.scrollHeight;
    },
    handleFile(e){
        this.attachments = e.target.files;
    },
    updateUser(id){
        axios.get(this.server+'/api/v1/user/user-data-by-id/'+id).then(res=>{
            if(res.data.resCode == 200){
                this.user = res.data.data
            }
        })
    },
    deleteMsg(id){
        

        if( confirm('Are you sure you want to delete this reply?') )
        {
            axios.delete(this.server+'/api/v1/msg/delete/'+id,{
            params: {
                support: true
            }}).then(res=>{
                if(res.data.resCode == 200){
                   this.openMessage.replies = this.openMessage.replies.filter(msg => msg.id != id)
                }
            })
        }
        
    },
   linkify(inputText) {
       var text=inputText;
	  var exp = `/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig`;
	  var text1=text.replace(exp, "<a  class='text-blue-600' href='$1'>$1</a>");
	  var exp2 =/(^|[^\/])(www\.[\S]+(\b|$))/gim;
	  return text1.replace(exp2, '$1<a class="text-blue-600" target="_blank" href="http://$2">$2</a>');
    }
  },
  mounted(){
    
    axios.get(this.server+'/api/v1/msg/get-message/'+this.$route.params.id,{
        params: {
            support: true
        }})
      .then((response) =>{
        // console.log(response)
        if(response.data.resCode == 200){
        //   Auth.login(response.data.token, response.data.user)
            this.openMessage = response.data.data;
            this.markOpenMessageAsRead();
            this.updateUser(this.openMessage.from);
        }
      })
      .catch((error) => console.log(error))
      this.scrollToBottom();
  },
 
  
}
</script>